

function range(start, end) {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}

export function mark_bettable(checkbox, match_id) {
  $.ajax({
    url: "/ajax/mark_bettable",
    data: {
      match_id: match_id,
      bettable: checkbox.checked,
    },
    dataType: 'json',
    error: function (response) {
      Flash.post('error', `Spielmarkirung konne nicht geändert werden!<br>${response.responseJSON.msg}`)
    }
  });
}

export function set_quota(text_field, match_id, quota_idx) {
  $.ajax({
    url: "/ajax/set_quota",
    data: {
      match_id: match_id,
      quota_idx: quota_idx,
      quota: text_field.value.replace(/,/g,'.'),
    },
    dataType: 'json',
    error: function (response) {
      Flash.post('error', `Quote konnte nicht geändert werden!<br>${response.responseJSON.msg}`)
    }
  });
}

export function place_bet(selection, member_id, match_id) {
  $.ajax({
    url: "/ajax/place_bet",
    data: {
      member_id: member_id,
      match_id: match_id,
      bet: selection.value,
    },
    dataType: 'json',
    error: function (response) {
      Flash.post('error', `Tipp konnte nicht abgegeben werden!<br>${response.responseJSON.msg}`)
    }
  });
}

function get_bet_results(matchday_table) {
  let bet_results = new Array();
  $(matchday_table).find('tbody > tr[id^=match]').each(function(){
    let score_arr = $(this).find('td[id^=score]').text().split(' - ');
    let score = score_arr.pop() - score_arr.pop()
    let match_bet_results = new Array();
    $(this).find('td[id^=bet]').each(function(){
      if( (score < 0 && $(this).text() === '1') ||
          (score == 0 && $(this).text() === 'X') ||
          (score > 0 && $(this).text() === '2') ) {
        match_bet_results.push(true);
      }
      else {
        match_bet_results.push(false);
      }
    });
    bet_results.push(match_bet_results);
  });
  return bet_results;
}

function mark_correct_bets(matchday_table, bet_results) {
  $(matchday_table).find('tbody > tr[id^=match]').each(function(match_idx){
    if(match_idx < 0 || match_idx >= bet_results.length) {
      return;
    }
    $(this).find('td[id^=bet]').each(function(member_idx){
      if(member_idx < 0 || member_idx >= bet_results[match_idx].length) {
        return;
      }
      if( bet_results[match_idx][member_idx] ) {
        $(this).addClass('correct-bet');
      }
      else {
        $(this).removeClass('correct-bet');
      }
    });
  });
}

function mark_winnings(matchday_table, bet_results) {
  $(matchday_table).find('tbody > tr[name=winnings]').each(function(){
    let combination = $(this).find('td[name=combination]');
    let matches;
    if(combination.length === 0){
      matches = range(1, bet_results.length);
    }
    else {
      matches = combination.text().split('-');
    }
    $(this).find('td[name=winning]').each(function(member_idx){
      let earned = true;
      matches.forEach(function(match){
        let match_idx = parseInt(match) - 1;
        if(match_idx < 0 || match_idx > bet_results.length ||
           member_idx < 0 || member_idx > bet_results[match_idx].length) {
          return;
        }
        earned &= bet_results[match_idx][member_idx];
      });
      if(earned){
        $(this).addClass('earned-winning');
      }
      else {
        $(this).removeClass('earned-winning');
      }
    });
  });
}

function update_scores(matchday_table, scores) {
  jQuery.each(scores, function(match_id, score_info){
    let score_field = $(`#score-${match_id}`);
    if(score_field.length === 0){
      Flash.post('warning', `Spielstandfeld nicht gefunden für Spiel Nr ${match_id}`);
      return;
    }

    let score_changed = false;
    let old_scores = score_field.text().split('-');
    let old_score2 = parseInt(old_scores.pop());
    let old_score1 = parseInt(old_scores.pop());

    if(score_info.score_team1 < old_score1 ||
      score_info.score_team2 < old_score2) {
      score_changed = true;
      Flash.post('warning',"Tor wurde zurückgepfiffen!");
    }
    else if(score_info.score_team1 > old_score1) {
      score_changed = true;
      Flash.post('info', `Toooooor! Für ${score_info.team1},
                          Torschütze: ${score_info.goal_getter}
                          in der ${score_info.match_minute}. Minute`);
    }
    else if(score_info.score_team2 > old_score2) {
      score_changed = true;
      Flash.post('info', `Toooooor! Für ${score_info.team2},
                          Torschütze: ${score_info.goal_getter}
                          in der ${score_info.match_minute}. Minute`);
    }

    if(score_changed){
      score_field.text(`${score_info.score_team1} - ${score_info.score_team2}`);
      let bet_results = get_bet_results(matchday_table);
      mark_correct_bets(matchday_table, bet_results);
      mark_winnings(matchday_table, bet_results);
    }

  });
}

export function setup_matchday_table(matchday_table, matchday_end_time_str) {
  let bet_results = get_bet_results(matchday_table);
  mark_correct_bets(matchday_table, bet_results);
  mark_winnings(matchday_table, bet_results);

  if(moment(matchday_end_time_str, 'DD.MM.YYYY HH:mm:ss', 'de').isAfter(moment())) {

    window.score_timer = setInterval(function(){
      $.ajax({
        url: "/ajax/current_scores",
        dataType: 'json',
        success: function (response) {
          update_scores(matchday_table, response.scores);
        },
        error: function (response) {
          Flash.post('error', `Spielstand konnte nicht abgefragt werden!<br>${response.responseJSON.msg}`)
        }
      });
    }, 10000);
  }
}

export function lock_matchday(matchday_id){
    $.ajax({
        url: "/ajax/lock_matchday",
        data: {
            matchday_id: matchday_id,
        },
        dataType: 'json',
        success: function (response) {
            Flash.post('info', response.msg)
        },
        error: function (response) {
            Flash.post('error', `Spieltag konnte nicht eingefroren werden<br>${response.responseJSON.msg}`)
        }
    });
}
