class Flash {

  static post(severity, text) {
    switch (severity) {
      case "success":
      case "notice":
        this.success(text);
        break;
      case "warning":
        this.warning(text);
        break;
      case "error":
      case "alert":
        this.error(text);
        break;
      case "info":
      default:
        this.info(text);
    }
  }

  static info(text, flashID = ++Flash.posted) {
    let type = "alert-info";
    let symbol = '<i class="glyphicon glyphicon-info-sign"></i>';
    this._render(type, symbol, text, flashID);
  };

  static success(text, flashID = ++Flash.posted) {
    let type = "alert-success";
    let symbol = '<i class="glyphicon glyphicon-ok-circle"></i>';
    this._render(type, symbol, text, flashID);
  };

  static warning(text, flashID = ++Flash.posted) {
    let type = "alert-warning";
    let symbol = '<i class="glyphicon glyphicon-warning-sign"></i>';
    this._render(type, symbol, text, flashID, 30000);
  };

  static error(text, flashID = ++Flash.posted) {
    let type = "alert-danger";
    let symbol = '<i class="glyphicon glyphicon-remove-circle"></i>';
    this._render(type, symbol, text, flashID, 60000);
  };

  static _render(type, symbol, text, flashID, visibleTime = 20000) {

    let msg = `<div class="alert ${type}" role="alert" id="flash-${flashID}">
                ${symbol}
                <button type='button' class='close' aria-label='Close' onClick="$('#flash-${flashID}').fadeOut(400, function () { $(this).remove(); })">
                  <span aria-hidden='true'>&times;</span>
                </button>
                <div class="alert-content">
                  ${text}
                </div>
              </div>`;

    // Append formatted message to the dom.
    $('#application-flash').append(msg).fadeIn(1000);

    setTimeout(function () {
      $(`#flash-${flashID}`).fadeOut(1000, function () {
        $(this).remove();
      })
    }, visibleTime);
  };
}

Flash.posted = 0;

export default Flash;
