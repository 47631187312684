
import moment from 'moment'
window.moment = moment
moment.locale('de')

import Flash from '../utils/Flash';
window.Flash = Flash;

import * as utils from '../utils/utils';
window.utils = utils;

require("chartkick")
require("chart.js")

$(document).on('turbolinks:load', function () {

  if (window.score_timer) {
    clearInterval(window.score_timer);
  }

  $('div[name=matchday-table]').each(function () {
    let matchday_date = $(this).find('div[name=matchday_end_time]').text();
    utils.setup_matchday_table(this, matchday_date);
  });
});
